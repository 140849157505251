import React, { createContext, useState } from 'react';

const FilterContext = createContext({ filters: {}, setFilters: null, where: '', setWhere: null, checkIn: '', setCheckIn: null, checkOut: '', setCheckOut: null, guests: '', setGuests: null, join: '', setJoin: null, whereResults: null, setWhereResults: null, checkInResults: null, setCheckInResults: null, checkOutResults: null, setCheckOutResults: null, guestsResults: null, setGuestsResults: null });

const FilterProvider = ({ children }) => {
    // Add your provider logic here
    const [filters, setFilters] = useState({});

    const [where, setWhere] = useState('');
    const [checkIn, setCheckIn] = useState('');
    const [checkOut, setCheckOut] = useState('');
    const [guests, setGuests] = useState('');

    const [join, setJoin] = useState('');

    const [whereResults, setWhereResults] = useState(null);
    const [checkInResults, setCheckInResults] = useState(null);
    const [checkOutResults, setCheckOutResults] = useState(null);
    const [guestsResults, setGuestsResults] = useState(null);

    const getProviders = () => {
        return {
            filters,
            setFilters,
            where,
            setWhere,
            checkIn,
            setCheckIn,
            checkOut,
            setCheckOut,
            guests,
            setGuests,
            join,
            setJoin,
            whereResults,
            setWhereResults,
            checkInResults,
            setCheckInResults,
            checkOutResults,
            setCheckOutResults,
            guestsResults,
            setGuestsResults
        }
    }

    return (
        <FilterContext.Provider value={getProviders()}>
            {children}
        </FilterContext.Provider>
    );
};

export { FilterContext, FilterProvider };