import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import './App.scss';

import Snackbar from '@mui/material/Snackbar';

import Navigation from './components/navigation/Navigation';
import NavigationRoutes from './routes/NavigationRoutes';
import Footer from './components/footer/Footer';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';

export default function App({ callback }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    callback.onUpdate = () => {
      console.log('service worker update waiting');
      setSnackbarMessage('A new version is available: exit the app to update');
      setSnackbarOpen(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app">
      <Navigation />
      <div className='page-container'>
        <NavigationRoutes />
      </div>
      <Footer />
      <ScrollToTop />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={60000}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
      />
    </div>
  );
}


App.propTypes = {
  callback: propTypes.object
};
