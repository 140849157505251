import React, { Fragment, useContext } from 'react';

import { FilterContext } from '../contexts/FilterContext';

import TopSearch from '../components/top-search/TopSearch';
import GridView from '../components/grid-view/GridView';

import topBackground from '../images/TopBackgroundLow.webp';

const SearchPage = () => {
    const { filters } = useContext(FilterContext);

    return (
        <Fragment>
            <div className='container page-fill'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='top-img-card'>
                            <div style={{ height: '120px', backgroundColor: '#EBEBEB', borderRadius: '0px 0px 18px 18px' }}>
                                <img className='img-fluid' style={{height: '120px', width: '100%', objectFit: 'cover'}} src={topBackground} alt='top background' />
                            </div>
                            <div className='top-img-text'>
                                <TopSearch/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 pt-5'>
                        <GridView limit={8} skeletonLimit={8} setMapData={(e) => null} infoUrl={'/inligting'} filters={filters} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SearchPage;
