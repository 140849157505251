import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import HomePage from '../pages/HomePage';
import SearchPage from '../pages/SearchPage';
import DetailPage from '../pages/DetailPage';
import ListingPage from '../pages/ListingPage';
import SiteMapPage from '../pages/SiteMapPage';
import WebsitePolicyPage from '../pages/WebsitePolicyPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';

import ClientListingPage from '../pages/client/ListingPage';

const NavigationRoutes = () => {
    return (
        <UserContext.Consumer>
            {({ user, setUser }) => (
                <Routes>
                    <Route exact={true} path="/" element={<HomePage />} />

                    <Route exact={true} path="/soekbyons" element={<SearchPage />} />
                    <Route exact={true} path="/inligting/:id" element={<DetailPage />} />

                    {!user?.uid && <Route exact={true} path="/lysbyons" element={<ListingPage />} />}

                    <Route exact={true} path="/werfkaart" element={<SiteMapPage />} />
                    <Route exact={true} path="/webtuistebeleid" element={<WebsitePolicyPage />} />
                    <Route exact={true} path="/termeenvoorwaardes" element={<TermsAndConditionsPage />} />

                    {user?.uid && <Route exact={true} path="/:client/lysbyons" element={<ClientListingPage />} />}

                    <Route exact={true} path="*" element={<HomePage />} />
                </Routes>
            )}
        </UserContext.Consumer>
    );
}

export default NavigationRoutes;
