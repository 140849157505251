import { collection, getDoc, doc, addDoc } from 'firebase/firestore';
import { db } from '../config/FirebaseConfig';
//import { getAuth } from 'firebase/auth';

class UserService {

    getUserById = async (id) => {
        try {
            const snapshot = await getDoc(doc(db, "user", id));
            return { ...snapshot.data(), id: snapshot.id };
        } catch (error) {
            return {};
        }
    }

    createUser = async (listing) => {
        try {
            await addDoc(collection(db, 'user'), listing);
            return true;
        } catch (error) {
            console.error('Error creating user: ', error);
            return false;
        }
    }
}

export default UserService;