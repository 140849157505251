import React, { useContext} from 'react';

import { Button, Box } from '@mui/material';

import { UserContext } from '../contexts/UserContext';

import topBackground from '../images/TopBackgroundMedium.webp';

const ListingPage = () => {
    const { setSignUpModalOpen } = useContext(UserContext);
    return (
        <div className='container page-fill'>
            <div className='row'>
                <div className='col-12'>
                    <div className='top-img-card'>
                        <img className='img-fluid' src={topBackground} alt='top background' />
                    </div>
                </div>
            </div>

            <div className='text-center pt-5'>
                <h5>Lys by ons</h5>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='text-center pt-3 pb-3'>
                        <p className='text-justify'>
                            Wil jy jou stukkie jagparadys deel met avontuurlustige jagliefhebbers van reg oor Suid-Afrika? Sluit aan by ons jagtog-verblyfnetwerk en laat jou eiendom 'n bestemming word vir diegened wat die opwinding van die jagveld soek!
                            <br />
                            <br />
                            As gasheer bied jy nie net 'n verblyf nie, maar 'n ware jagervaring in 'n idilliese omgewing. Jou eiendom kan 'n oase van rus en avontuur wees vir diegene wat die natuur waardeer en op soek is na 'n onvergeetlike jagtog. Van weelderige jagplase tot rustieke wildsverblywe, ons platform gee jou die geleentheid om jou stukkie paradys met die wêreld te deel.
                        </p>
                        <Box display='flex' justifyContent='center' className='my-3'>
                            <Button variant='outlined' color='primary' onClick={() => setSignUpModalOpen(true)}>Sluit aan</Button>
                        </Box>
                        <p>
                            Sluit vandag aan en word deel van ons passievolle gemeenskap van jagliefhebbers en natuurontdekkers. Jou unieke aanbod kan die drome van avontuurlustiges laat waar word en jou as 'n topbestemming vir jagtog-ervarings vestig. Maak gereed om jou deure oop te stel vir 'n wêreld van jagliefhebbers wat jou jagland met groot opgewondenheid sal ontdek!
                        </p>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ListingPage;
