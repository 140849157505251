import React, { useState, useContext } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import ContactUsService from '../../services/ContactUsService';
import { UserContext } from '../../contexts/UserContext';

const ContactUs = () => {
    const { user } = useContext(UserContext);
    const service = new ContactUsService();
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(null);
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(null);
    const [subject, setSubject] = useState('');
    const [subjectValid, setSubjectValid] = useState(null);
    const [message, setMessage] = useState('');
    const [messageValid, setMessageValid] = useState(null);

    const [showSuccess, setShowSuccess] = useState('');

    const handleContactUs = async (e) => {
        e.preventDefault();
        if (!subjectValid || !messageValid || !nameValid || !emailValid) {
            if (!nameValid) setNameValid(false);
            if (!emailValid) setEmailValid(false);
            if (!subjectValid) setSubjectValid(false);
            if (!messageValid) setMessageValid(false);
            return;
        }
        service.createContactUs(
            {
                name: name,
                email: email,
                subject: subject,
                message: message,
                user: user ? user.uid
                    : null
            }
        );
        setShowSuccess("Dankie vir jou boodskap. Ons sal jou binnekort kontak.");
        setName('');
        setNameValid(null);
        setEmail('');
        setEmailValid(null);
        setSubject('');
        setSubjectValid(null);
        setMessage('');
        setMessageValid(null);
        setTimeout(() => {
            setShowSuccess("");
        }, 10000);
    }

    return (
        <Box sx={{ paddingTop: 5, textAlign: 'center' }}>
            <Typography variant="h5" gutterBottom>Kontak ons</Typography>
            <form noValidate onSubmit={handleContactUs}>
                {showSuccess === '' && (
                    <Box sx={{ display: 'grid', gap: 3 }}>
                        <TextField
                            label="Naam"
                            variant="outlined"
                            fullWidth
                            value={name}
                            onChange={(e) => {
                                const value = e.target.value;
                                setNameValid(value.trim() !== '');
                                setName(value);
                            }}
                            error={nameValid === false}
                            helperText={nameValid === false ? 'Verskaf asseblief jou naam.' : ''}
                        />
                        <TextField
                            label="E-pos adres"
                            variant="outlined"
                            fullWidth
                            type="email"
                            value={email}
                            onChange={(e) => {
                                const value = e.target.value;
                                setEmailValid(/^\S+@\S+\.\S+$/.test(value.trim()));
                                setEmail(value);
                            }}
                            error={emailValid === false}
                            helperText={emailValid === false ? 'Verskaf asseblief ' + (email === '' ? 'jou' : 'geldige') + ' e-pos adres.' : ''}
                        />
                        <TextField
                            label="Onderwerp"
                            variant="outlined"
                            fullWidth
                            value={subject}
                            onChange={(e) => {
                                const value = e.target.value;
                                setSubjectValid(value.trim() !== '');
                                setSubject(value);
                            }}
                            error={subjectValid === false}
                            helperText={subjectValid === false ? 'Verskaf asseblief \'n onderwerp.' : ''}
                        />
                        <TextField
                            label="Boodskap"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={message}
                            onChange={(e) => {
                                const value = e.target.value;
                                setMessageValid(value.trim() !== '');
                                setMessage(value);
                            }}
                            error={messageValid === false}
                            helperText={messageValid === false ? 'Verskaf asseblief \'n boodskap.' : ''}
                        />
                        <Box sx={{ textAlign: 'right' }}>
                            <Button type="submit" variant="contained" color="primary" sx={{ borderRadius: '20px' }}>
                                Stuur
                            </Button>
                        </Box>
                    </Box>
                )}
                {showSuccess !== '' && (
                    <Box sx={{ display: 'grid', gap: 3 }}>
                        <Typography variant="body1" color="textSecondary" align="center">
                            {showSuccess}
                        </Typography>
                    </Box>
                )}
            </form>
        </Box>
    );
};

export default ContactUs;
