import React, { useState, useEffect, useContext } from 'react';
import {
    TextField,
    Button,
    Box,
    Stack,
    Chip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    IconButton,
} from '@mui/material';

import { UserContext, UserRoles } from '../../contexts/UserContext';

import ListingService from '../../services/ListingService';

//import TestData from './TestData.json';

const required = false;
const allowedImageCount = 1;

const ListingForm = () => {
    const { user, userDetails, checkUserRole } = useContext(UserContext);
    const service = new ListingService();

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        full_description: [''],
        type: '',
        price: 0,
        location: {
            address: '',
            city: '',
            province: '',
            country: '',
            latitude: '',
            longitude: '',
        },
        check_in: '',
        check_out: '',
        minimum_nights: 1,
        guests: 1,
        minimum_guests: 1,
        bedrooms: 0,
        bathrooms: 0,
        species: [],
        features: [],
        thumbnail: '',
        images: Array(allowedImageCount).fill(''),
        rating: '',
        reviews: [],
        terms_and_conditions: [],
        unavailable_dates: [],
        host: {
            uid: user?.uid,
            name: '',
            surname: '',
            avatar: '',
            email: '',
            bio: '',
        },
        active: false,
    });

    const types = ['Plaas Huid', 'Selfsorg-Chalets', 'Tent Kamp', 'Kamp'];

    const [speciesInput, setSpeciesInput] = useState('');
    const [featuresInput, setFeaturesInput] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePriceChange = (e) => {
        let value = e.target.value;

        // Remove any non-numeric characters except the decimal point
        value = value.replace(/[^0-9.]/g, '');

        // Allow only one decimal point
        const decimalCount = (value.match(/\./g) || []).length;
        if (decimalCount > 1) {
            value = value.slice(0, value.lastIndexOf('.'));
        }

        // Ensure it has no more than two decimal places
        if (value.includes('.')) {
            const [integer, decimal] = value.split('.');
            if (decimal.length > 2) {
                value = `${integer}.${decimal.slice(0, 2)}`;
            }
        }

        // Update state with formatted value
        setFormData({ ...formData, price: value });
    };

    const handleLocationChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            location: { ...formData.location, [name]: value },
        });
    };

    const handleSpeciesInputChange = (e) => {
        setSpeciesInput(e.target.value);
    };

    const handleFeaturesInputChange = (e) => {
        setFeaturesInput(e.target.value);
    };

    const addChip = (field, value, setter) => {
        if (value && !formData[field].includes(value)) {
            setFormData({
                ...formData,
                [field]: [...formData[field], value],
            });
            setter(''); // Clear the input after adding the chip
        }
    };

    const removeChip = (field, value) => {
        setFormData({
            ...formData,
            [field]: formData[field].filter((item) => item !== value),
        });
    };

    const handleImageChange = (index, value) => {
        const updatedImages = [...formData.images];
        updatedImages[index] = value;
        setFormData({ ...formData, images: updatedImages });
    };

    const addImageField = () => {
        setFormData({
            ...formData,
            images: [...formData.images, ''],
        });
    };

    const removeImageField = (index) => {
        if (formData.images.length > allowedImageCount) {
            const updatedImages = formData.images.filter((_, i) => i !== index);
            setFormData({ ...formData, images: updatedImages });
        }
    };

    const moveImageUp = (index) => {
        if (index > 0) {
            const updatedImages = [...formData.images];
            const temp = updatedImages[index];
            updatedImages[index] = updatedImages[index - 1];
            updatedImages[index - 1] = temp;
            setFormData({ ...formData, images: updatedImages });
        }
    };

    const moveImageDown = (index) => {
        if (index < formData.images.length - 1) {
            const updatedImages = [...formData.images];
            const temp = updatedImages[index];
            updatedImages[index] = updatedImages[index + 1];
            updatedImages[index + 1] = temp;
            setFormData({ ...formData, images: updatedImages });
        }
    };

    const handleFullDescriptionChange = (index, value) => {
        const updatedFullDescription = [...formData.full_description];
        updatedFullDescription[index] = value;
        setFormData({ ...formData, full_description: updatedFullDescription });
    };

    const addFullDescriptionField = () => {
        setFormData({
            ...formData,
            full_description: [...formData.full_description, ''],
        });
    };

    const moveFullDescriptionUp = (index) => {
        if (index > 0) {
            const updatedFullDescription = [...formData.full_description];
            const temp = updatedFullDescription[index];
            updatedFullDescription[index] = updatedFullDescription[index - 1];
            updatedFullDescription[index - 1] = temp;
            setFormData({ ...formData, full_description: updatedFullDescription });
        }
    };

    const moveFullDescriptionDown = (index) => {
        if (index < formData.full_description.length - 1) {
            const updatedFullDescription = [...formData.full_description];
            const temp = updatedFullDescription[index];
            updatedFullDescription[index] = updatedFullDescription[index + 1];
            updatedFullDescription[index + 1] = temp;
            setFormData({ ...formData, full_description: updatedFullDescription });
        }
    };

    const deleteFullDescription = (index) => {
        if (formData.full_description.length > 1) {
            const updatedFullDescription = formData.full_description.filter(
                (_, i) => i !== index
            );
            setFormData({ ...formData, full_description: updatedFullDescription });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Add form validation here
        if (formData.name && user?.uid && (checkUserRole(UserRoles.HOST) || checkUserRole(UserRoles.ADMIN))) {
            await service.createListing(formData);
            //console.log(formData);
        } else {
            alert('Please fill all required fields!');
        }
    };

    useEffect(() => {
        if (userDetails?.id) {
            setFormData({
                ...formData, 
                host: {
                    uid: userDetails.id,
                    name: userDetails.name,
                    surname: userDetails.surname,
                    email: userDetails.email,
                    avatar: userDetails.avatar,
                    bio: userDetails.bio,
                },
            })
        }
        //setFormData(TestData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails]);

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                {/* Name */}
                <TextField
                    label="Name"
                    fullWidth
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    margin="normal"
                    required={true}
                    sx={{ paddingRight: '96px' }}
                />

                {/* Description */}
                <TextField
                    label="Description"
                    fullWidth
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    margin="normal"
                    required={required}
                    sx={{ paddingRight: '96px' }}
                />

                <Box sx={{ marginBottom: 3 }} /> {/* Added space */}

                {/* Full Description */}
                <h5 variant="h6" style={{ marginRight: '96px', fontSize: '16px' }}>
                    Full Description (Add as many as you want)
                </h5>
                <Stack spacing={3}>
                    {formData.full_description.map((description, index) => (
                        <Stack direction="row" spacing={2} alignItems="center" key={index}>
                            <TextField
                                label={`Full Description ${index + 1}`}
                                fullWidth
                                value={description}
                                onChange={(e) => handleFullDescriptionChange(index, e.target.value)}
                                margin="normal"
                                multiline
                                rows={4}
                                required={required}
                            />
                            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '80px' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    {index > 0 && (
                                        <IconButton
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => moveFullDescriptionUp(index)}
                                        >
                                            <i className="material-icons">arrow_upward</i>
                                        </IconButton>
                                    )}
                                    {index < formData.full_description.length - 1 && (
                                        <IconButton
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => moveFullDescriptionDown(index)}
                                        >
                                            <i className="material-icons">arrow_downward</i>
                                        </IconButton>
                                    )}
                                </Box>

                                {index >= 1 && <IconButton
                                    variant="outlined"
                                    color="error"
                                    onClick={() => deleteFullDescription(index)}
                                >
                                    <i className="material-icons">delete</i>
                                </IconButton>}
                            </Box>
                        </Stack>
                    ))}
                </Stack>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={addFullDescriptionField}
                    sx={{ marginTop: 2, marginRight: '96px' }}
                >
                    Add New Full Description
                </Button>

                {/* Type Dropdown */}
                <FormControl fullWidth margin="normal" required={required} sx={{ paddingRight: '96px' }}>
                    <InputLabel>Type</InputLabel>
                    <Select
                        label="Type"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        sx={{ textAlign: 'left' }}
                    >
                        {types.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Price */}
                <TextField
                    label="Price"
                    fullWidth
                    name="price"
                    value={formData.price}
                    onChange={handlePriceChange}
                    margin="normal"
                    required={required}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">R</InputAdornment>,
                    }}
                    type="text" // Changed to text to allow more controlled input
                    sx={{ paddingRight: '96px' }}
                />

                <Box sx={{ marginBottom: 3 }} /> {/* Added space */}

                {/* Species (Chips with Add Button) */}
                <h5 variant="h6" style={{ marginRight: '96px' }}>
                    Species
                </h5>
                <Stack direction="row" spacing={0} flexWrap="wrap" sx={{ paddingRight: '96px', gap: '5px' }}>
                    {formData.species.map((species, index) => (
                        <Chip
                            key={index}
                            label={species}
                            onDelete={() => removeChip('species', species)}
                        />
                    ))}
                </Stack>
                <Stack direction="row" spacing={2} sx={{ marginTop: 1 }}>
                    <TextField
                        label="Add Species"
                        fullWidth
                        value={speciesInput}
                        onChange={handleSpeciesInputChange}
                        margin="normal"
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '80px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            {speciesInput?.trim() !== "" && <IconButton
                                variant="outlined"
                                color='secondary'
                                onClick={() => speciesInput?.trim() !== "" && addChip('species', speciesInput, setSpeciesInput)}
                            >
                                <i className="material-icons">add</i>
                            </IconButton>}
                        </Box>
                    </Box>
                </Stack>

                <Box sx={{ marginBottom: 3 }} /> {/* Added space */}

                {/* Location */}
                <h5 variant="h6" style={{ marginRight: '96px' }}>
                    Location
                </h5>
                <Stack direction="row" spacing={2} sx={{ paddingRight: '96px' }}>
                    <TextField
                        label="Address"
                        fullWidth
                        name="address"
                        value={formData.location.address}
                        onChange={handleLocationChange}
                        margin="normal"
                        required={required}
                    />
                    <TextField
                        label="City"
                        fullWidth
                        name="city"
                        value={formData.location.city}
                        onChange={handleLocationChange}
                        margin="normal"
                        required={required}
                    />
                    <TextField
                        label="Province"
                        fullWidth
                        name="province"
                        value={formData.location.province}
                        onChange={handleLocationChange}
                        margin="normal"
                        required={required}
                    />
                </Stack>
                <TextField
                    label="Country"
                    fullWidth
                    name="country"
                    value={formData.location.country}
                    onChange={handleLocationChange}
                    margin="normal"
                    required={required}
                    sx={{ paddingRight: '96px' }}
                />

                <Box sx={{ marginBottom: 1 }} /> {/* Added space */}

                <Stack direction="row" spacing={2} sx={{ paddingRight: '96px' }}>
                    <TextField
                        label="Latitude"
                        fullWidth
                        name="latitude"
                        value={formData.location.latitude}
                        onChange={handleLocationChange}
                        margin="normal"
                        required={required}
                    />
                    <TextField
                        label="Longitude"
                        fullWidth
                        name="longitude"
                        value={formData.location.longitude}
                        onChange={handleLocationChange}
                        margin="normal"
                        required={required}
                    />
                </Stack>

                {/* Space Between Check In and Guests */}
                <Box sx={{ marginBottom: 3 }} /> {/* Added space */}

                {/* Check In and Check Out */}
                <Stack direction="row" spacing={2} sx={{ paddingRight: '96px' }}>
                    <TextField
                        label="Check In"
                        fullWidth
                        name="check_in"
                        value={formData.check_in}
                        onChange={handleChange}
                        margin="normal"
                        required={required}
                    />
                    <TextField
                        label="Check Out"
                        fullWidth
                        name="check_out"
                        value={formData.check_out}
                        onChange={handleChange}
                        margin="normal"
                        required={required}
                    />
                    <TextField
                        label="Minimum Nights"
                        fullWidth
                        name="minimum_nights"
                        value={formData.minimum_nights}
                        onChange={handleChange}
                        margin="normal"
                        required={required}
                        type='number'
                        slotProps={{
                            input: {
                                inputProps: { 
                                    min: 1 
                                }
                            }
                        }}
                    />
                </Stack>

                {/* Space Between Check In and Guests */}
                <Box sx={{ marginBottom: 3 }} /> {/* Added space */}

                <Stack direction="row" spacing={2} sx={{ paddingRight: '96px' }}>
                    <TextField
                        label="Guests"
                        fullWidth
                        name="guests"
                        value={formData.guests}
                        onChange={handleChange}
                        margin="normal"
                        required={required}
                        type='number'
                        slotProps={{
                            input: {
                                inputProps: { 
                                    min: 1 
                                }
                            }
                        }}
                    />
                    <TextField
                        label="Minimum Guests"
                        fullWidth
                        name="minimum_guests"
                        value={formData.minimum_guests}
                        onChange={handleChange}
                        margin="normal"
                        required={required}
                        type='number'
                        slotProps={{
                            input: {
                                inputProps: { 
                                    min: 1 
                                }
                            }
                        }}
                    />
                    <TextField
                        label="Bedrooms"
                        fullWidth
                        name="bedrooms"
                        value={formData.bedrooms}
                        onChange={handleChange}
                        margin="normal"
                        required={required}
                        type='number'
                        slotProps={{
                            input: {
                                inputProps: { 
                                    min: 0 
                                }
                            }
                        }}
                    />
                    <TextField
                        label="Bathrooms"
                        fullWidth
                        name="bathrooms"
                        value={formData.bathrooms}
                        onChange={handleChange}
                        margin="normal"
                        required={required}
                        type='number'
                        slotProps={{
                            input: {
                                inputProps: { 
                                    min: 0
                                }
                            }
                        }}
                    />
                </Stack>


                <Box sx={{ marginBottom: 3 }} /> {/* Added space */}

                {/* Features (Chips with Add Button) */}
                <h5 variant="h6" style={{ marginRight: '96px' }}>
                    Features
                </h5>
                <Stack direction="row" spacing={0} flexWrap="wrap" sx={{ paddingRight: '96px', gap: '5px' }}>
                    {formData.features.map((feature, index) => (
                        <Chip
                            key={index}
                            label={feature}
                            onDelete={() => removeChip('features', feature)}
                        />
                    ))}
                </Stack>
                <Stack direction="row" spacing={2} sx={{ marginTop: 1 }}>
                    <TextField
                        label="Add Feature"
                        fullWidth
                        value={featuresInput}
                        onChange={handleFeaturesInputChange}
                        margin="normal"
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '80px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            {featuresInput?.trim() !== '' && <IconButton
                                variant="outlined"
                                color='secondary'
                                onClick={() => featuresInput?.trim() !== '' && addChip('features', featuresInput, setFeaturesInput)}
                            >
                                <i className="material-icons">add</i>
                            </IconButton>}
                        </Box>
                    </Box>
                </Stack>

                {/* Thumbnail URL */}
                <TextField
                    label="Thumbnail URL"
                    fullWidth
                    name="thumbnail"
                    value={formData.thumbnail}
                    onChange={handleChange}
                    margin="normal"
                    required={required}
                    sx={{ paddingRight: '96px' }}
                />

                <Box sx={{ marginBottom: 3 }} /> {/* Added space */}

                {/* Images */}
                <h5 variant="h6" style={{ marginRight: '96px', fontSize: '16px' }}>
                    Images (minimum {allowedImageCount} URLs, you can add more and arrange them)
                </h5>
                <Stack spacing={3}>
                    {formData.images.map((imageUrl, index) => (
                        <Stack direction="row" spacing={2} alignItems="center" key={index}>
                            <TextField
                                label={`Image URL ${index + 1}`}
                                fullWidth
                                value={imageUrl}
                                onChange={(e) => handleImageChange(index, e.target.value)}
                                margin="normal"
                                required={required}
                            />
                            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '80px' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                                    {index > 0 && (
                                        <IconButton
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => moveImageUp(index)}
                                        >
                                            <i className="material-icons">arrow_upward</i>
                                        </IconButton>
                                    )}
                                    {index < formData.images.length - 1 && (
                                        <IconButton
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => moveImageDown(index)}
                                        >
                                            <i className="material-icons">arrow_downward</i>
                                        </IconButton>
                                    )}
                                </Box>

                                {index >= allowedImageCount && (
                                    <IconButton
                                        variant="outlined"
                                        color="error"
                                        onClick={() => removeImageField(index)}
                                    >
                                        <i className="material-icons">delete</i>
                                    </IconButton>
                                )}
                            </Box>
                        </Stack>
                    ))}
                </Stack>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={addImageField}
                    sx={{ marginTop: 2, marginRight: '96px' }}
                >
                    Add New Image URL
                </Button>

                {/* Submit Button */}
                <Box sx={{ marginTop: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{ marginRight: '96px' }}
                    >
                        Submit Listing
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default ListingForm;
