import React, { Fragment, useEffect, useState } from 'react';
import './ItemView.scss';
import ListingService from '../../services/ListingService';
import { AsyncImage } from 'loadable-image';
import { Blur } from 'transitions-kit';
import Currency from '../currency/Currency';
import { Box } from '@mui/material';

import ItemViewSkeletonLoading from '../item-view-skeleton-loading/ItemViewSkeletonLoading';

import Map from '../map/Map';

const ItemView = ({ id }) => {
    const service = new ListingService();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        // eslint-disable-next-line
    }, [id]);

    const getData = async () => {
        setLoading(true);
        const response = await service.getListingById(id);
        setData(response);
        setLoading(false);
    };

    useEffect(() => {
        //(data);
    }, [data]);

    return (
        <Fragment>
            {!loading ?
                <div className="item-view-component">
                    <div className='row d-md-block d-none'>
                        <div className='col-12 my-2 info-summary'>
                            <div className='item-title'>
                                {data.name}
                                {data.rating && <span className='item-rating'>
                                    <i className="material-icons">star</i>{data.rating ? data.rating % 1 === 0 ? data.rating : data.rating.toFixed(2) : ' Nog geen resensies nie'}
                                </span>}
                            </div>
                            {data.location.province && <div className='item-subtitle mb-3'><i className="material-icons" style={{ fontSize: '14px', marginTop: '4px' }}>place</i> {data.location.province}, {data.location.country}</div>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-6 pe-md-1'>
                            <AsyncImage
                                className='main-image'
                                src={data.images[0]}
                                loader={<div style={{ background: '#EBEBEB' }} />}
                                Transition={Blur}
                                alt={data.name}
                            />
                        </div>
                        <div className='col-6 ps-1 d-md-block d-none'>
                            <div className='row'>
                                <div className='col-6 pe-1 pb-1'>
                                    <AsyncImage
                                        className='extra-image-01'
                                        src={data.images[1]}
                                        loader={<div style={{ background: '#EBEBEB' }} />}
                                        Transition={Blur}
                                        alt={data.name}
                                    />
                                </div>
                                <div className='col-6 ps-1 pb-1'>
                                    <AsyncImage
                                        className='extra-image-02'
                                        src={data.images[2]}
                                        loader={<div style={{ background: '#EBEBEB' }} />}
                                        Transition={Blur}
                                        alt={data.name}
                                    />
                                </div>
                                <div className='col-6 pe-1 pt-1'>
                                    <AsyncImage
                                        className='extra-image-03'
                                        src={data.images[3]}
                                        loader={<div style={{ background: '#EBEBEB' }} />}
                                        Transition={Blur}
                                        alt={data.name}
                                    />
                                </div>
                                <div className='col-6 ps-1 pt-1'>
                                    <AsyncImage
                                        className='extra-image-04'
                                        src={data.images[4]}
                                        loader={<div style={{ background: '#EBEBEB' }} />}
                                        Transition={Blur}
                                        alt={data.name}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-7 my-2 pt-md-4 info-summary' style={{ minHeight: '800px' }}>
                            <div className='item-title d-block d-md-none'>
                                {data.name}
                                {data.rating && <span className='item-rating'>
                                    <i className="material-icons">star</i>{data.rating ? data.rating % 1 === 0 ? data.rating : data.rating.toFixed(2) : ' Nog geen resensies nie'}
                                </span>}
                            </div>
                            {data.location.province && <div className='item-subtitle d-block d-md-none'><i className="material-icons" style={{ fontSize: '14px', marginTop: '4px' }}>place</i> {data.location.province}, {data.location.country}</div>}
                            <div className='item-divider d-md-none' />

                            {data?.species?.length > 0 && <div className='item-animals'>
                                <div className='item-animals-title'>Beskikbare Wild om te Jag</div>
                                <div className='item-animals-list'>
                                    {data.species.map((game, index) => (
                                        <div key={index} className='item-animals-item'>{game}</div>
                                    ))}
                                </div>
                            </div>}
                            {data?.species?.length > 0 && <div className='item-divider' />}

                            {data?.full_description?.length > 0 && data?.full_description[0]?.trim() !== "" && <div className='item-full-description'>
                                <div className='item-full-description-title'>Oorsig</div>
                                <div className='item-full-description-text'>
                                    {data.full_description.map((text, index) => (
                                        <p key={index} className='item-full-description-item'>{text}</p>
                                    ))}
                                </div>
                            </div>}

                            {data?.full_description?.length > 0 && data?.full_description[0]?.trim() !== "" && <div className='item-divider' />}

                            <div className='item-price d-md-none'>
                                {data.price ? <Fragment><Currency value={data.price} /> per nag</Fragment> : ''}
                            </div>
                            {data.guests && <div className='item-subtitle d-md-none'>Plek vir {data.guests} gaste</div>}
                            <div className='item-booking d-md-none'>
                                <div ></div>
                            </div>
                            {data.check_in && data?.check_out && <div className='item-checkin-checkout d-md-none'>
                                <i className='material-icons'>info</i> Inklok {data.check_in} en Uitklok {data.check_out}
                            </div>}

                            {data.check_in && data?.check_out && <div className='item-divider d-md-none' />}

                            {data?.features?.length > 0 && <div className='item-features'>
                                <div className='item-features-title'>Geriewe</div>
                                <div className='item-features-list' style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))' }}>
                                    {data.features.map((feature, index) => (
                                        <div key={index} className='item-features-item'>{feature}</div>
                                    ))}
                                </div>
                            </div>}
                            {data?.features?.length > 0 && <div className='item-divider' />}


                            {data?.terms_and_conditions?.length > 0 && data?.terms_and_conditions[0]?.trim() !== "" && <div className='item-terms-and-conditions'>
                                <div className='item-terms-and-conditions-title'>Terme en Voorwaardes</div>
                                <ul className='item-terms-and-conditions-list'>
                                    {data.terms_and_conditions.map((term, index) => (
                                        <li key={index} className='item-terms-and-conditions-item'>{term}</li>
                                    ))}
                                </ul>
                            </div>}

                            {data?.terms_and_conditions?.length > 0 && data?.terms_and_conditions[0]?.trim() !== "" && <div className='item-divider' />}


                            {data?.reviews?.length >0 && <div className='item-reviews'>
                                <div className='item-reviews-title'>Resensies</div>
                                <div className='item-reviews-list'>
                                    {data.reviews.map((review, index) => (
                                        <div key={index} className='item-review'>
                                            <div className='item-review-text'>{review}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>}

                            {data?.reviews?.length >0 && <div className='item-divider' />}

                            {data?.host?.name && <div className='item-host'>
                                <Box sx={{ display: 'flex', gap: '20px' }}>
                                    <AsyncImage
                                        className='extra-image-01'
                                        src={data.host.avatar}
                                        loader={<div style={{ background: '#EBEBEB' }} />}
                                        Transition={Blur}
                                        alt={data.name}
                                        style={{ borderRadius: '50%', minWidth: '100px', minHeight: '100px', maxWidth: '100px', maxHeight: '100px' }}
                                    />
                                    <Box>
                                        <div className='item-host-title'>Gasheer</div>
                                        <div className='item-host-name'>{data.host.name} {data.host.surname}</div>
                                        <div className='item-host-description'>{data.host.bio}</div>
                                    </Box>
                                </Box>
                            </div>}
                        </div>

                        <div className='col-12 col-md-5 d-none d-md-block my-2 sticky-card-contianer'>
                            <div className='sticky-card' style={{ minHeight: '400px' }}>
                                <div className='sticky-card-price'>
                                    {data.price ? <Fragment><Currency value={data.price} /> per nag</Fragment> : ''}
                                </div>

                                {data?.guests && <div className='sticky-card-subtitle'>Plek vir {data.guests} gaste</div>}

                                <div className='sticky-card-booking'>
                                    <div ></div>
                                </div>

                                {data?.check_in && data?.check_out &&<div className='sticky-card-checkin-checkout'>
                                    <i className='material-icons'>info</i> Inklok {data.check_in} en Uitklok {data.check_out}
                                </div>}
                            </div>
                        </div>

                        {data?.location?.latitude && data?.location?.longitude && <div className='col-12 '>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='map'>
                                        <div className='map-title'>Kaart</div>
                                        <div className='map-item'>
                                            <Map coordinates={data?.location?.latitude && data?.location?.longitude ? [{ latitude: data.location.latitude, longitude: data.location.longitude, tooltip: 'Tooltip', popup: 'Popup' }] : null} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                :
                <ItemViewSkeletonLoading />
            }
        </Fragment>
    );

};

export default ItemView;