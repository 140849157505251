import React, { useEffect, useState/*, useContext*/ } from 'react';
import './GridView.scss';
import GridCard from '../grid-card/GridCard';
import GridCardSekeletonLoading from '../grid-card-skeleton-loading/GridCardSkeletonLoading';
import ListingService from '../../services/ListingService';
import { Button, Box } from '@mui/material';
//import { UserContext } from '../../contexts/UserContext';

const GridView = ({ limit, skeletonLimit, sliding = false, setMapData, infoUrl = '/inligting', filters = [] }) => {
    //const { user } = useContext(UserContext);
    const service = new ListingService();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        // eslint-disable-next-line
    }, [filters/*user*/]);


    /*const getData = async () => {
        setLoading(true);
        const response = await service.getListings(limit);
        const tmp = response.map(item => ({
            latitude: item?.location?.latitude,
            longitude: item?.location?.longitude,
        }));
        setMapData(tmp);
        setData(response);
        setLoading(false);
    };*/

    const getData = async () => {
        setLoading(true);
        try {
            // Fetch paginated listings with limit and lastVisible document
            const { listingList } = await service.getListings(limit, filters);
            // Process the data (you can modify this as per your original logic)
            const tmp = listingList?.map(item => ({
                latitude: item?.location?.latitude,
                longitude: item?.location?.longitude,
            }));

            // Update map data
            setMapData(tmp);
            // Append the new listings to the existing data
            setData(listingList);

            setLoading(false);
        } catch (error) {
            console.error("Error fetching listings:", error);
            setLoading(false);
        }
    };


    return (
        <div className={`grid-view ${sliding ? 'horizontal-scrollable' : ''}`}>
            {!loading && <div className='row  pt-2 pb-3 '>
                {data.map((item, index) => (
                    <GridCard item={item} key={index} infoUrl={infoUrl} />
                ))}
            </div>}
            {loading && <div className='row pt-2 pb-3'>
                <GridCardSekeletonLoading skeletonLimit={skeletonLimit} />
            </div>}
            {!sliding && <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
                <Button variant='outlined' color='primary' disabled={loading} onClick={() => getData()} >Laai meer</Button>
            </Box>}
        </div>
    );
};

export default GridView;
