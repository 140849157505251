import { collection, addDoc } from 'firebase/firestore';
import { db } from '../config/FirebaseConfig';

class ContactUsService {
    createContactUs = async (listing) => {
        try {
            await addDoc(collection(db, 'contactus'), listing);
            return true;
        } catch (error) {
            console.error('Error creating contact us: ', error);
            return false;
        }
    }
}

export default ContactUsService;