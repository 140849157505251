import React, { useRef, useState, useEffect, Fragment } from "react"
import './Map.scss'
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const Map = ({ coordinates }) => {
    const mapRef = useRef(null);

    const [mapCenter, setMapCenter] = useState(null);
    const [mapZoom, setMapZoom] = useState(5);

    useEffect(() => {
        if (coordinates?.length > 0 && coordinates?.length < 2) { // FIX AVARAGE COORDINATES
            let sumLat = 0;
            let sumLon = 0;
            for (let i = 0; i < coordinates?.length; i++) {
                sumLat += coordinates[i].latitude;
                sumLon += coordinates[i].longitude;
            }
            const avgLat = sumLat / coordinates.length;
            const avgLon = sumLon / coordinates.length;
            const centralPos = [-28 || avgLat, 26 || avgLon]; // FIX AVARAGE COORDINATES
            setMapZoom(7);
            setMapCenter(centralPos);
        } else {
            setMapCenter([-28, 25]);
            setMapZoom(5);
        }
    }, [coordinates])

    return (
        <Fragment>
            {mapCenter && <MapContainer className="map-container" scrollWheelZoom={false} center={mapCenter} zoom={mapZoom} ref={mapRef} style={{ height: "100%", width: "100%", borderRadius: '15px' }}>
                {<TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />}
                {coordinates?.map((coordinate, index) => (
                    <Marker key={index} position={[coordinate?.latitude, coordinate?.longitude]} icon={L.icon({ iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png', iconSize: [30, 30], iconAnchor: [12, 41] })}>
                        {coordinate?.tooltip && <Tooltip>{coordinate?.tooltip}</Tooltip>}
                        {coordinate?.popup && <Popup>{coordinate?.popup}</Popup>}
                    </Marker>
                ))}
            </MapContainer>}
        </Fragment>
    );
};

export default Map;
