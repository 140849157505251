import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import { FilterProvider } from './contexts/FilterContext';
import { UserProvider } from './contexts/UserContext';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.js';
import "bootstrap-icons/font/bootstrap-icons.css";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const callback = {};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter >
        <FilterProvider>
            <UserProvider>
                <App callback={callback} /> {/* passing the callback */}
            </UserProvider>
        </FilterProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register({
    onUpdate: () => {
        if (callback.onUpdate) {
            callback.onUpdate(); // delegating the callback
        }
    }
});
