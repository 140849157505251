import React, { Fragment, useEffect } from 'react';
import './GridCard.scss';
import Currency from '../currency/Currency';
import { AsyncImage } from 'loadable-image'
import { Blur } from 'transitions-kit'
import { useNavigate } from 'react-router-dom';

const GridCard = ({ item, infoUrl = '/inligting' }) => {
    const navigate = useNavigate();

    useEffect(() => {

    }, [item]);

    return (
        <div className='grid-card-container col-12 col-sm-6 col-md-4 col-lg-3 mt-3 mb-2'>
            <div className='card' onClick={() => navigate(`${infoUrl}/${item.id}`)}>
                <AsyncImage
                    className='card-img-top'
                    src={item.thumbnail}
                    loader={<div style={{ background: '#EBEBEB' }} />}
                    Transition={Blur}
                    alt={item.name}
                />
                <div className='card-fav'><i className="material-icons">favorite_border</i></div>
                <div className='card-body'>
                    <div className='card-rating'>
                        {item?.rating && <div><i className="material-icons">star</i>{item.rating % 1 === 0 ? item.rating : item.rating.toFixed(2)}</div>}
                    </div>
                    <div className='card-title'>
                        {item.name}
                    </div>
                    <div className="card-subtitle text-muted">
                        {item?.location?.province && <div><i className="material-icons" style={{ fontSize: '12px', marginTop: '4px' }}>place</i> {item?.location?.province}, {item?.location?.country}</div>}
                    </div>
                    {item?.location?.province && <div className="card-subtitle text-muted">
                        Plek vir {item.guests} gaste
                    </div>}
                    <div className='card-price'>
                        {item.price ? <Fragment><Currency value={item.price} /> per nag</Fragment> : ''}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GridCard;
