import React, { createContext, useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import UserService from '../services/UserService';

const UserContext = createContext({ user: {}, setUser: null, userDetails: {}, setUserDetails: null, contextDoneLoading: false, signUpModalOpen: false, setSignUpModalOpen: null, signInModalOpen: false, setSignInModalOpen: null });

export const UserRoles = {
    ADMIN: 'admin',
    HOST: 'host',
    USER: 'user',
    GUEST: 'guest'
};

const UserProvider = ({ children }) => {
    // Add your provider logic here
    const service = new UserService();
    const [user, setUser] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [signUpModalOpen, setSignUpModalOpen] = useState(false);
    const [signInModalOpen, setSignInModalOpen] = useState(false);

    const getUserDetails = async (id) => {
        if (id) {
            const response = await service.getUserById(user.uid);
            if (response?.active) {
                setUserDetails(response);
            }
        }
    }

    const checkUserRole = (role) => {
        return userDetails?.roles?.includes(role);
    }

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setUser(user);
                getUserDetails(user?.uid);
            }
        });
        return () => unsubscribe();
        // eslint-disable-next-line
    }, [user]);

    const getProviders = () => {
        return {
            user,
            setUser,
            userDetails,
            setUserDetails,
            signUpModalOpen,
            setSignUpModalOpen,
            signInModalOpen,
            setSignInModalOpen,
            checkUserRole
        }
    }

    return (
        <UserContext.Provider value={getProviders()}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };