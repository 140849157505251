import React, { useState, useContext, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { Link } from 'react-router-dom';
import './Navigation.scss';
import logo from '../../logo.svg'
import { signOut } from 'firebase/auth';
import { auth } from '../../config/FirebaseConfig';

import { TextField, Box, Button } from '@mui/material';

import Popup from '../popup/Popup';

import AuthService from '../../services/AuthService';

const Navigation = () => {
    const { user, userDetails, setUser, signUpModalOpen, setSignUpModalOpen } = useContext(UserContext);

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(null);

    const [show, setShow] = useState('collapse navbar-collapse');
    const [modalId, setModalId] = useState(null);

    const onBurgerMenuClick = (menu = true) => {
        if (window?.innerWidth < 768) {
            if (show === 'collapse navbar-collapse' && menu) {
                setShow('navbar-collapse collapsing');
                setTimeout(() => setShow('collapse navbar-collapse show'), 100)
            } else {
                setShow('navbar-collapse collapsing');
                setTimeout(() => setShow('collapse navbar-collapse'), 100)
            }
        }
    }

    const handleLogout = () => {
        signOut(auth).then(() => {
            setUser({})
            navigate("/");
        });
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!emailValid || !passwordValid) {
            if (!emailValid) setEmailValid(false);
            if (!passwordValid) setPasswordValid(false);
            return;
        }
        const service = new AuthService();
        const response = await service.signIn(email, password);
        if (response) {
            setUser(response);
            setModalId(null);
        }
        setModalId(null);
    }

    const handleSignup = async (e) => {
        e.preventDefault();
        if (!emailValid || !passwordValid || !confirmPasswordValid || password !== confirmPassword) {
            if (!emailValid) setEmailValid(false);
            if (!passwordValid) setPasswordValid(false);
            if (!confirmPasswordValid) setConfirmPasswordValid(false);
            return;
        }

        const service = new AuthService();
        const response = await service.createUser(email, password);
        if (response) {
            setUser({})
            setModalId('popupModalLogin');
        }
        setModalId('popupModalLogin');
    }

    const modalLogin = () => {
        setEmail('');
        setEmailValid(null);
        setPassword('');
        setPasswordValid(null);
        setConfirmPassword('');
        setConfirmPasswordValid(null);
        setModalId('popupModalLogin');
    }

    const modalSignup = () => {
        setEmail('');
        setEmailValid(null);
        setPassword('');
        setPasswordValid(null);
        setConfirmPassword('');
        setConfirmPasswordValid(null);
        setModalId('popupModalSignup');
    }

    useEffect(() => {
        if (signUpModalOpen) {
            modalSignup();
            setSignUpModalOpen(false);
        }
        // eslint-disable-next-line
    }, [signUpModalOpen]);

    return (
        <div className='navigation-component'>
            {/*To Navigation*/}
            <nav className="navbar fixed-top navbar-expand-md navbar-light bg-white border-bottom">
                <div className="container">
                    <Link className="navbar-brand" to="/" onClick={() => onBurgerMenuClick(false)}>
                        <img src={logo} alt="" height="38" className="d-inline-block align-text-top" />
                    </Link>
                    <button className="btn btn-outline-dark d-block d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={() => onBurgerMenuClick()}>
                        <i className="material-icons font-large-mui menu-icon">menu</i>
                    </button>
                    <div className={`${show} justify-content-center`} id="navbarNav">
                        <ul className="navbar-nav ml-auto mr-auto">
                            <li className="nav-item rounded-pill mt-4 mt-md-0">
                                <Link className="nav-link" to="/soekbyons" onClick={() => onBurgerMenuClick()}>Soek by ons</Link>
                            </li>
                            <li className="nav-item rounded-pill">
                                {!user?.uid && <Link className="nav-link" to="/lysbyons" onClick={() => onBurgerMenuClick()}>Lys by ons</Link>}
                                {user?.uid && <Link className="nav-link" to={`/${user?.uid}/lysbyons`} onClick={() => onBurgerMenuClick()}>Lys by ons</Link>}
                            </li>
                        </ul>
                    </div>
                    <div className="dropdown d-none d-md-block">
                        {userDetails?.avatar ?
                            <img src={userDetails?.avatar} alt={userDetails.name} className="rounded-circle" width="38" height="38" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" />
                            :
                            <button className="btn btn-outline-dark rounded-pill px-2 py-1 menu-icon profile-icons" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="material-icons font-large-mui">account_circle</i>
                            </button>}
                        <ul className="dropdown-menu dropdown-menu-end mt-2 border-0" aria-labelledby="dropdownMenuButton">
                            {!user?.uid && <li>
                                {<Link className="dropdown-item py-2" onClick={() => modalSignup()}>Sluit aan</Link>}

                            </li>}
                            {!user?.uid && <li><Link className="dropdown-item py-2" onClick={() => modalLogin()}>Teken in</Link></li>}
                            {user?.uid && <li><Link className="dropdown-item py-2" onClick={() => handleLogout()}>Teken uit</Link></li>}
                        </ul>
                    </div>
                </div>
            </nav>
            {/*Bottom App Bar*/}
            <nav className="navbar fixed-bottom navbar-light bg-white d-block d-md-none border-top">
                <div className="container justify-content-center">
                    <Link className="btn btn-white py-0 button-hover-disable" to="/soekbyons">
                        <i className="material-icons font-large-mui appbar-icon">search</i>
                        <p className='m-0 font-small'>Soek</p>
                    </Link>
                    <div className='mx-sm-5 mx-2'></div>
                    <Link className="btn btn-white py-0 button-hover-disable" to="/wenslys">
                        <i className="material-icons font-large-mui appbar-icon">favorite</i>
                        <p className='m-0 font-small'>Wenslys</p>
                    </Link>
                    <div className='mx-sm-5 mx-2'></div>
                    <Link className="btn btn-white py-0 button-hover-disable" onClick={() => { !user?.uid ? modalLogin() : handleLogout() }}>
                        {userDetails?.avatar ? <img src={userDetails?.avatar} alt={userDetails.name} className="rounded-circle" width="24" height="24"/> : <i className="material-icons font-large-mui appbar-icon">account_circle</i>}

                        {!user?.uid && <p className='m-0 font-small'>Teken in</p>}
                        {user?.uid && <p className='m-0 font-small'>Teken uit</p>}
                    </Link>
                </div>
            </nav>
            {/* Login Modal */}
            {modalId === 'popupModalLogin' && <Popup modalHeading={'Teken in'} modalId={modalId} setModalId={(e) => setModalId(e)} >
                <Fragment>
                    <form className="needs-validation" noValidate onSubmit={(e) => handleLogin(e)}>
                        <div className="modal-body form-modal row g-3">
                            <Box sx={{ display: 'grid', gap: 3 }}>
                                <TextField
                                    id="email"
                                    label="E-pos adres"
                                    type="email"
                                    value={email}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setEmailValid(/^\S+@\S+\.\S+$/.test(value.trim()));
                                        setEmail(value);
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    error={emailValid === false}
                                    helperText={emailValid === false && `Please provide ${email === '' ? 'your' : 'a valid'} email address.`}
                                />
                                <TextField
                                    id="password"
                                    label="Wagwoord"
                                    type="password"
                                    value={password}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setPasswordValid(value.trim() !== '');
                                        setPassword(value);
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    error={passwordValid === false}
                                    helperText={passwordValid === false && 'Please provide a password.'}
                                />
                            </Box>
                        </div>
                        <div className="modal-footer">
                            <Button
                                onClick={() => setModalId(null)}
                                variant="outlined"
                                color="secondary"
                                sx={{ borderRadius: '20px' }}
                            >
                                MAAK TOE
                            </Button>

                            <Button
                                onClick={() => modalSignup()}
                                variant="text"
                                sx={{ borderRadius: '20px' }}
                            >
                                Sluit aan
                            </Button>

                            <Button
                                type="button"
                                onClick={(e) => handleLogin(e)}
                                variant="contained"
                                color="primary"
                                sx={{ borderRadius: '20px' }}
                            >
                                Teken in
                            </Button>
                        </div>
                    </form>
                </Fragment>
            </Popup>}
            {/* Signup Modal */}
            {modalId === 'popupModalSignup' && <Popup modalHeading={'Sluit aan'} modalId={modalId} setModalId={(e) => setModalId(e)} >
                <Fragment>
                    <form noValidate onSubmit={(e) => handleSignup(e)}>
                        <div className="modal-body form-modal row g-3">
                            <Box sx={{ display: 'grid', gap: 3 }}>
                                {/* Email Input */}
                                <TextField
                                    label="E-pos adres"
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                    value={email}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setEmailValid(/^\S+@\S+\.\S+$/.test(value.trim()));
                                        setEmail(value);
                                    }}
                                    error={emailValid === false}
                                    helperText={emailValid === false ? `Please provide ${email === '' ? 'your' : 'a valid'} email address.` : ''}
                                />
                                {/* Password Input */}
                                <TextField
                                    label="Wagwoord"
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                    value={password}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setPasswordValid(value.trim() !== '');
                                        setPassword(value);
                                    }}
                                    error={passwordValid === false}
                                    helperText={passwordValid === false ? 'Please provide a password.' : ''}
                                />
                                {/* Confirm Password Input */}
                                <TextField
                                    label="Bevestig wagwoord"
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setConfirmPasswordValid(value.trim() !== '');
                                        setConfirmPassword(value);
                                    }}
                                    error={confirmPasswordValid === false}
                                    helperText={confirmPasswordValid === false ? 'Please confirm your password.' : ''}
                                />
                            </Box>
                        </div>
                        <div className="modal-footer">
                            <Button
                                onClick={() => setModalId(null)}
                                variant="outlined"
                                color="secondary"
                                sx={{ borderRadius: '20px' }}
                            >
                                MAAK TOE
                            </Button>

                            <Button
                                onClick={() => modalLogin()}
                                variant="text"
                                sx={{ borderRadius: '20px' }}
                            >
                                Teken in
                            </Button>

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ borderRadius: '20px' }}
                            >
                                SLUIT AAN
                            </Button>
                        </div>
                    </form>
                </Fragment>
            </Popup>}
        </div>
    );
};

export default Navigation;
