import { collection, getDocs, getDoc, doc, addDoc, query, orderBy, limit as firestoreLimit, where } from 'firebase/firestore';
import { db } from '../config/FirebaseConfig';
//import { getAuth } from 'firebase/auth';

class ListingService {
    /*getListings = async (limit) => {
        try {
            //const snapshot = await getDocs(query(collection(db, 'listing'), where('uid', '==', getAuth().currentUser.uid)));
            const snapshot = await getDocs(collection(db, 'listing'));
            const listingList = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id}));
            return listingList;
        } catch (error) {
            return [];
        }
    }*/

    // '<' operator is used to filter based on less than
    // '<=' operator is used to filter based on less than or equal to
    // '==' operator is used to filter based on equal to
    // '>' operator is used to filter based on greater than
    // '>=' operator is used to filter based on greater than or equal to
    // '!=' operator is used to filter based on not equal to
    // 'array-contains' operator is used to filter based on array contains
    // 'array-contains-any' operator is used to filter based on array contains any
    // 'in' operator is used to filter based on in
    // 'not-in' operator is used to filter based on not in


    //active - collection
    //type - collectionGroup
    //species - collection
    //name - field

    getListings = async (limit, filters) => {
        try {
            let listingsQuery = query(
                collection(db, 'listing'),
                where('active', '==', true),
                orderBy('name'),
                firestoreLimit(limit)
            );


            // If a filter is provided, use it dynamically
            if (filters?.length > 0) {
                filters.forEach(filter => {
                    listingsQuery = query(
                        listingsQuery,
                        where(filter.filterField, filter.operator, filter.filterValue)
                    );
                });
            }

            const snapshot = await getDocs(listingsQuery);
            const listingList = snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));

            return { listingList, total: snapshot.size || 0 };
        } catch (error) {
            console.error('Error fetching listings with pagination and dynamic operator:', error);
            return { listingList: [], total: 0 };
        }
    };


    getListingById = async (id) => {
        try {
            const snapshot = await getDoc(doc(db, "listing", id));
            return { ...snapshot.data(), id: snapshot.id };
        } catch (error) {
            return {};
        }
    }

    createListing = async (listing) => {
        try {
            await addDoc(collection(db, 'listing'), listing);
            return true;
        } catch (error) {
            console.error('Error creating listing: ', error);
            return false;
        }
    }
}

export default ListingService;