import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../logo.svg';
import './Footer.scss';

const Footer = () => {
    return (
        <div className='container footer-container mt-5'>
            <footer className='mb-md-0 mb-5 pb-md-0 pb-2'>
                <div>
                    <ul className="nav justify-content-center">
                        <li className="nav-item"><Link to="/werfkaart" className="nav-link px-2 text-muted">Werfkaart</Link></li>
                        <li className="nav-item"><Link to="/webtuistebeleid" className="nav-link px-2 text-muted">Webtuiste beleid</Link></li>
                        <li className="nav-item"><Link to="/termeenvoorwaardes" className="nav-link px-2 text-muted">Terme en voorwaardes</Link></li>
                    </ul>
                </div>
                <div className="d-flex flex-wrap justify-content-between align-items-center py-3 my-2 border-top">
                    <div className="col-md-4 d-flex align-items-center">
                        <Link to="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                            <img src={logo} alt="" height="18" className="d-inline-block align-text-top" />
                        </Link>
                        <span className="mb-3 mb-md-0 text-muted">© 2024 (Pty) Ltd</span>
                    </div>
                    <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                        <li className="ms-3"><Link className="text-muted" to="/facebook"><i className="bi bi-facebook"></i></Link></li>
                        <li className="ms-3"><Link className="text-muted" to="/instagram"><i className="bi bi-instagram"></i></Link></li>
                    </ul>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
