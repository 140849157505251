import React from 'react';

import ListingForm from '../../components/listing-form/ListingForm';

import topBackground from '../../images/TopBackgroundMedium.webp';

const ListingPage = () => {
    return (
        <div className='container page-fill'>
            <div className='row'>
                <div className='col-12'>
                    <div className='top-img-card'>
                        <img className='img-fluid' src={topBackground} alt='top background' />
                    </div>
                </div>
            </div>

            <div className='text-center pt-5'>
                <h5>Lys by ons</h5>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <ListingForm />
                </div>
            </div>
        </div>
    );
};

export default ListingPage;